const problem = {
    state: {
        problemList: [], //问题录入
        problemListOffline: [],//离线问题录入
        issue:"", //问题零件录入关键词
        area:{},

    },
    actions: {
        setIssue({commit}, index) {
            commit('SETIssue', index);
        },
        setArea({commit}, index) {
            commit('SETArea', index);
        },
        setProblemsOffline({commit}, info) {
            commit('SETProblemsOffline', info);
        },
        deleteProblemsOffline({commit}, index) {
            commit('DELProblemsOffline', index);
        },
        emptyProblemsOffline({commit}) {
            commit('EMPTYProblemsOffline');
        },

        setProblems({commit}, info) {
            commit('SETProblems', info);
        },
        emptyProblems({commit}) {
            commit('EMPTYProblems');
        },
        deleteProblems({commit}, index) {
            commit('DELProblems', index);
        }
    },
    mutations: {
        SETArea:(state, date)=>{
            state.area=date;
        }  ,
        SETIssue:(state, date)=>{
            state.issue=date;
        }  ,
        //提交问题录入列表
        SETProblemsOffline: (state, date) => {
            state.problemListOffline.push(date);
        },
        DELProblemsOffline: (state, index) => {
            state.problemListOffline.splice(index, 1)
        },
        //清除问题录入列表
        EMPTYProblemsOffline: (state) => {
            state.problemListOffline = []
        },

        //提交问题录入列表
        SETProblems: (state, date) => {
            state.problemList.push(date);
        },
        DELProblems: (state, index) => {
            state.problemList.splice(index, 1)
        },
        //清除问题录入列表
        EMPTYProblems: (state) => {
            state.problemList = []

        },
    }

}
export default problem
