import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import problem from './modules/problem'
import getters from './getters'

Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        user,
        problem
    },
    getters,
})

export default store
