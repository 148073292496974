const getters = {
    userName: state => state.user.userName,
    userInfo:state => state.user.userInfo,
    carInfo: state => state.user.carInfo,
    problemList: state => state.problem.problemList,
    problemListOffline: state => state.problem.problemListOffline,
    route: state => state.user.route,
    issue: state => state.problem.issue,
    area: state => state.problem.area,

}
export default getters
