

<template>
  <div class="bg">
    <div style="font-size: 13px;line-height: 24px;background: #fff;border-radius: 10px;padding: 10px;text-align: center">
      <span style="font-size: 16px;">扫码验证</span>

    </div>
    <div style="background: #fff;width: 95%;margin: 10px auto;border-radius: 10px;padding: 10px">
      <div style="margin: 10px auto;width: 300px">
        <img  :src="'https://m.hzmed.top/api/'+obj.prodImg"  width="300"/>
      </div>
      <div style="font-size: 14px;padding-top: 10px">
        <div style="margin-top: 20px" v-if="obj.prodName">商品名称：{{obj.prodName}}</div>
<!--        <div style="margin-top: 20px" v-if="obj.companyName">销售商追溯溯源：{{obj.companyName}}</div>-->
<!--        <div style="margin-top: 20px" v-if="obj.serviceCompanyName">发货方：{{obj.serviceCompanyName}}</div>-->
<!--        <div  style="margin-top: 20px" v-if="obj.sendTime">收货时间：{{formatDate(obj.sendTime)}}</div>-->
        <!--        <div  style="margin-top: 20px">产品介绍：{{obj.prodNote}}</div>-->
      </div>
    </div>
    <div style="font-size: 16px;margin: 10px">您是第 <span style="color: #F0A12B;font-size: 30px">{{obj.count+1}}</span> 次扫码者
    </div>
    <div style="font-size: 13px;line-height: 24px;background: #fff;width: 95%;margin: 10px auto;border-radius: 10px;padding: 10px">
      <span style="font-size: 14px;font-weight: bold">请刮开右边二维码扫描验证真伪。</span>
        <div>{{obj.companyName}}</div>
    </div>

  </div>
</template>

<script>

import {search} from "@http/APIConfig";
import CryptoJS from 'crypto-js'
export default {
  name: "root",
  data(){

    return{
      obj:{
        "companyName": null,
        "serviceCompanyName": null,
        "sendTime": null,
        "count": null,
        "prodImg": null,
        "prodName": null,
        "prodNote": null
      }
    }
  },
  methods: {
      /* 解密 */
    encrypt(word, AES_KEY) {
      const key = CryptoJS.enc.Utf8.parse(AES_KEY);
      let decrypt = CryptoJS.AES.decrypt(word, key,{ mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
      return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    },

    formatDate (d) {
      var date = new Date(d);
      var YY = date.getFullYear() + '-';
      var MM =
          (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) + '-';
      var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      var hh =
          (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      var mm =
          (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
          ':';
      var ss =
          date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();

      if(!d){
        return  ''
      }else{
        return YY + MM + DD + ' ' + hh + mm + ss;
      }

    },
    parseQuery(url) {
      const res = {};
      const query = (url.split("?")[1] || "")

          .replace(/^(\?|#|&)/, "");
      if (!query) {
        return res;
      }
      query.split("&").forEach(param => {
        // const parts = param.replace(/\+/g, " ").split("=");
        const parts = param.split("=");

        const key = decodeURIComponent(parts.shift());
        const val = parts.length > 0 ? decodeURIComponent(parts.join("=")) : null;
        if (res[key] === undefined) {
          res[key] = val;
        } else if (Array.isArray(res[key])) {
          res[key].push(val);
        } else {
          res[key] = [res[key], val];
        }
      });
      return res;
    },
  },
  created() {
    let param
    let query = this.$route.query
    // query =  this.parseQuery('http://127.0.0.1:9090/test?code=HZMED000600051667914875978000001&verifyCode=CoigNxBRvElzzVWC8MMNOy8jwvH+DYdDiWXKrycLo9uBAjiLVVSRuyQqS75DwpYI')

    if(Object.keys(query).length===1){
      if(query.hasOwnProperty('vcCode')){
          let str = decodeURIComponent(escape(atob(query.vcCode)))
        param={
          type:2,
          //   code:this.encrypt(str,'2468864224688642')
          code:query.vcCode
        }
      }else{
          let str = decodeURIComponent(escape(atob(query.scCode)))
        param={
          type:1,
          // code:this.encrypt(str,'1357753113577531')
          code:query.scCode
        }
      }

    }else{
      if(query.hasOwnProperty('sourceCode')){
        param={
          type:1,
          // code:encodeURI(escape(query.verifyCode))
          code:query.sourceCode
        }
      }else{
        param={
          type:2,
          code:  query.code
        }
      }
    }
    console.log(param)




    search(param).then(res=>{
      this.obj = res.data
    })

  },
}
</script>

<style scoped>

</style>