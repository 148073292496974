<template>
  <div >
<!--    fixed safe-area-inset-top class="navBar"-->
    <van-nav-bar :title="title"  @click-left="onClickLeft" @click-right="onClickRight">
<!--      <template v-if="$slots.left" slot="left">-->
<!--        <slot name="left"></slot>-->
<!--      </template>-->
<!--      v-else-if="isBack"-->
      <template  slot="left">
        <van-icon name="arrow-left" size="18" :color="backIconColor" />
      </template>

      <template slot="right">


      </template>
    </van-nav-bar>



    <div class="nav-top-placeholder" v-if="isPlaceholder"></div>
  </div>
</template>

<script>
import { NavBar } from "vant";
import { Icon } from "vant";

export default {
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
  },
  props: {
    // 是否显示返回按钮，默认为true，优先级低于slot
    isBack: { type: [Boolean, String], default: true },
    go:{
      type:String,
      default:''
    },
    // 默认返回按钮颜色
    backIconColor: { type: String, default: "#323F50" },
    // 标题
    title: { type: String, default: "" },
    // 固定在顶部时，是否在标签位置生成一个等高的占位元素
    isPlaceholder: { type: Boolean, default: true },
  },
  data(){
    return{
      showPopover:false,
      actions: [{ text: '修改姓名' }, { text: '推出账户' }],
      show:false,
    }
  },
  methods: {
    handleOk(){
      if(this.name == ''){
        this.$toast('请输入姓名')
        return
      }
      this.$store.commit('setUserName',this.name)
      this.show = false
    },
    onSelect(action) {
      if(action.text =="修改姓名"){
        this.show = true
      }

    },
    onClickLeft() {

      if(this.go){
        this.$router.push(this.go);
      }else{
        this.$router.go(-1);
      }

    },
    onClickRight() {

    },
  },
  created() { },
};
</script>

<style>

</style>
