<template>
  <div class="scanning-code chery-body">
    <van-nav-bar :title="title" class="nav-bar">
      <template #right>
        <userNav/>
      </template>
    </van-nav-bar>
    <van-search
        v-model="value"
        show-action
        placeholder="请输入搜索关键词"
        @input="onSearch"
    >
      <template #action>
        <span @click="onSearch">搜索</span>
      </template>
    </van-search>
    <div style="width: 95%;margin: 10px auto">
      <van-cell is-link @click="adressHiden = true" title="收货单位" :value="serviceCompanyName">
      </van-cell>
      <div style="display: flex;margin-top: 10px;justify-content: space-around">
        <van-button style="height: 28px" plain icon="enlarge" type="info" @click="photos = true">
          扫码
        </van-button>
        <van-button style="height: 28px" plain icon="checked" type="primary" @click="submit">
          确认提交
        </van-button>
      </div>
      <div
          style="width: 98%;margin: 10px auto;background: #fff;padding: 5px;text-align: center;   line-height:30px; border-radius: 5px">
        <!--        <ve-table   :columns="columns" :table-data="tableData"  />-->
        <div style="    display: flex;
    justify-content: space-around;
    font-size: 16px;
    font-weight: bold;
   ;">
          <div style="width: 80%">产品序列号</div>
          <div style="width: 20%">操作</div>
        </div>
        <div style="display: flex;font-size: 14px;    justify-content: space-around;" v-for="(item,index) in codeList"
             :key="index">
          <div style="width: 80%">{{ item }}</div>
          <div style="width: 20%">
            <van-button type="primary" size="mini" color="linear-gradient(to right, #ff6034, #ee0a24)"
                        @click="codeList.splice(index,1)">移除
            </van-button>
          </div>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="adressHiden" round position="bottom">
      <van-picker title="收货单位" show-toolbar :columns="filterAdressList"
                  @cancel="adressHiden = false"
                  @confirm="onConfirmProblemLevel"/>
    </van-action-sheet>
    <van-dialog v-model="photos" width="400" title="扫一扫" show-cancel-button :showConfirmButton="false">
      <div class="scanning-code-model" v-if="photos">
        <StreamBarcodeReader @decode="getCode"></StreamBarcodeReader>
        <!--        v-if="scanFlag"-->
      </div>
    </van-dialog>
  </div>


</template>
<script>

import {getServiceCompanys, deliverGood} from "@http/APIConfig";
import CryptoJS from "crypto-js";


export default {
  components: {
    userNav: () => import('@/components/userNav'),
    StreamBarcodeReader: () => import('@/components/StreamBarcodeReader'),
  },
  data() {
    return {
      title: '物流发货',
      value: "",
      adress: '',
      adressHiden: false,
      adressList: [],
      filterAdressList: [],
      photos: false,
      serviceCompanyName: '',
      serviceCompanyId: '',
      codeList: [],

      columns: [
        {field: "adasName", key: "adasName", title: "产品型号", align: "center"},
        {field: "adasName", key: "adasName", title: "二维码", align: "center"},
        {field: "adasName", key: "adasName", title: "数量", align: "center"},


        // { field: "result", key: "result", title: "结果", align: "center", renderBodyCell: ({ row, column, rowIndex }, h) => {
        //     if(row.result=='OK'){
        //       return ''
        //     }else if(row.result=='漏扫'){
        //       return (
        //           <span style="color:#96A1B0" >{row.result}</span>
        //       );
        //     }else{
        //       return (
        //           <span class="text-bold" >{row.result}</span>
        //       );
        //     }
        //   }, },
      ],
      tableData: [],


    };
  },

  methods: {
      /* 解密 */
      encrypt(word, AES_KEY) {
          const key = CryptoJS.enc.Utf8.parse(AES_KEY);
          let decrypt = CryptoJS.AES.decrypt(word, key,{ mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
          return CryptoJS.enc.Utf8.stringify(decrypt).toString();
      },

      parseQuery(url) {
      const res = {};
      const query = (url.split("?")[1] || "")

          .replace(/^(\?|#|&)/, "");
      if (!query) {
        return res;
      }
      query.split("&").forEach(param => {
        // const parts = param.replace(/\+/g, " ").split("=");
        const parts = param.split("=");

        const key = decodeURIComponent(parts.shift());
        const val = parts.length > 0 ? decodeURIComponent(parts.join("=")) : null;
        if (res[key] === undefined) {
          res[key] = val;
        } else if (Array.isArray(res[key])) {
          res[key].push(val);
        } else {
          res[key] = [res[key], val];
        }
      });
      return res;
    },
    onBeforeClose(action, done) {
      if (action === "confirm") {
        return done(false);
      } else {
        // 重置表单校验
        return done();
      }
    },

    submit() {
      if(this.codeList.length == 0){
        this.$toast.fail('请扫码提交')
        return
      }
      let param = this.codeList.map(item => {
        return {
          "code": item,
          "serviceCompanyId": this.serviceCompanyId,
          "serviceCompanyName": this.serviceCompanyName
        }
      })
      deliverGood(param).then(res => {
        if (res.code == 200) {
          this.$toast.success('发货成功')
          this.codeList = []
          this.serviceCompanyName = ''
          this.serviceCompanyId = ''
          this.getList()
        }
      })
    },
    onConfirmProblemLevel(value, index) {
      this.serviceCompanyName = value.text
      this.serviceCompanyId = value.value
      this.adressHiden = false
    },
    getCode(e) {
        let query =this.parseQuery(e)
        let code = ''
        if(Object.keys(query).length===1){
            if(query.hasOwnProperty('vcCode')){
                let str = decodeURIComponent(escape(atob(query.vcCode)))
                code=this.encrypt(str,'2468864224688642')
            }else{
                let str = decodeURIComponent(escape(atob(query.scCode)))
                 code = this.encrypt(str,'1357753113577531')
            }

        }else{
            code = this.parseQuery(e).code
        }


      if (this.codeList.includes(code)) {
        this.$toast.fail('重复扫码')
      } else {
        this.codeList.push(code)
      }
      this.photos = false
    },
    onSearch() {
      console.log(this.value)
      if(this.value == ''){
        this.filterAdressList = this.adressList
        return
      }
      this.filterAdressList = this.adressList.filter(item => {
        if (item.text.includes(this.value)) {
          return item
        }
      })

    },
    getList() {
      getServiceCompanys().then(res => {
        this.value = ''
        this.adressList = res.data.map(item => {
          return {
            text: item.serviceCompanyName,
            value: item.id
          }
        })
        this.filterAdressList = this.adressList

      })
    }

  },
  mounted() {
    this.getList()
       // this.getCode('https://m.hzmed.top/#/sc?code=HZMED003800141677334690722000001&sourceCode=dFk2aUFIR2hqTFhWN0hPSHRhcHpnVk5IbDUxNFhZZkx5Y2NCOVZDUXpPeHZOODgyVDNHRDh3SnQ5UFJOTU1JbA%3D%3D')
    //  let receiver;
    //  let  main = plus.android.runtimeMainActivity(); //获取activity
    // this.info = plus.android.runtimeMainActivity()
    //  receiver = plus.android.implements('io.dcloud.android.content.BroadcastReceiver', {
    //    onReceive: (context, intent) => { //实现onReceiver回调函数
    //      plus.android.importClass(intent); //通过intent实例引入intent类，方便以后的‘.’操作
    //      alert(intent.getStringExtra("scannerdata") ); //获取数据 ，这里就是扫码之后弹出来的返回值
    //      this.info2 = context
    //    }
    //  });
    //  let IntentFilter = plus.android.importClass('android.content.IntentFilter');
    //  let Intent = plus.android.importClass('android.content.Intent');
    //  let filter = new IntentFilter();
    //  filter.addAction("com.android.server.scannerservice.broadcast"); //监听扫码广播
    //  main.registerReceiver(receiver, filter); //注册监听
  }

};
</script>

<style lang="less">
.scanning-code .van-field__control {
  padding: 4px;
}

.van-field__left-icon .van-icon, .van-field__right-icon .van-icon {
  margin-top: 5px;
}

.scanning-code {


  .field-dialog {
    margin-bottom: 15px;
  }

  .dialog-btn {
    width: 127px;
    height: 37px;
    background: #006CDA;
    border-radius: 9px;
    font-weight: 400;
    color: #FFFFFF;
    font-size: 15px;
    display: block;
    margin: 24px auto 36px;
  }

  .scanning-code-body {

    .scanning-code-body-jump {
      padding-top: 170px
    }
  }

  .scanning-code-photo {
    text-align: center;
    margin: 0 auto 25px;
    width: 126px;
    height: 126px;
    background: #006CDA;
    box-shadow: 0px 3px 10px 0px rgba(63, 110, 255, 0.4);
    border-radius: 50%;

    .iconfont {
      font-size: 50px;
      color: #fff;
      line-height: 120px
    }
  }

  .scanning-code-text {
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #313E4F;
    text-align: center;
  }

  .scanning-code-model {
    width: 320px;
    margin: 0 auto;
    padding-top: 50px;

  }

  .dia-btn {
    font-size: 17px;
    font-weight: 400;
    color: #006CDA;
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #DBE1E8;
  }
}

.company-bottom {
  font-size: 12px;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 15px;
  color: #96A1B0;
}
</style>
