import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BaseNavBar from "./components/BaseNavBar.vue";
import store from './store';
import VueBus from 'vue-bus';
import './config/vant/vant.js'
import './config/css/global.css'

// import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
// import RecycleScroller  from 'vue-virtual-scroller'
// Vue.component('RecycleScroller', RecycleScroller)

import 'vue-easytable/libs/theme-default/index.css'
// 引入组件库
import { VeTable,  VeLoading } from "vue-easytable";
// Vue.component(VTable.name, VTable)										// 注册到全局
// Vue.component(VPagination.name, VPagination)
Vue.use(VeTable);
Vue.use(VeLoading);
Vue.use(VueBus);



// import BaseTopTabs from "./components/BaseTopTabs.vue";
// import JhRefreshView from "./components/JhRefreshView/index.vue";
// import BaseRefreshView from "./components/BaseRefreshView.vue";
// import BaiduMap from 'vue-baidu-map'
import vuescroll from 'vuescroll';

import  "amfe-flexible";


Vue.component('BaseNavBar', BaseNavBar)
// Vue.component('BaseTopTabs', BaseTopTabs)
// Vue.component('JhRefreshView', JhRefreshView)
// Vue.component('BaseRefreshView', BaseRefreshView)

Vue.use(vuescroll);

// Vue.use(BaiduMap, {
//   ak: ''  //  在此输入你自己的百度地图ak
// })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

