<template>
  <div ref="login" class="login">
    <div class="img-bg" style="font-size: 25px;color: #fff;height: 100px">
      <img src="../../assets/logo.png" style="width: 200px"/>
    </div>
    <div class="login-form" >
      <van-form @submit="toLogin">
        <span class="login-text" id="aaa">账号</span>
        <van-popover
            v-model="showPopover"
            trigger="click"
            :actions="actions"
            @select="onSelect"
        >
          <template #reference>
            <van-field
                @focus="focused"
                v-model="username"
                name="账号"
                left-icon="contact"
                placeholder="账号"
                :rules="[{ required: true, message: '请填写账号' }]"
            />
          </template>
        </van-popover>
        <span class="login-text">密码</span>
        <div>
          <van-field
              @focus="focused"
              class="password" v-model="password" @click-left-icon="passwordShow=!passwordShow" clearable name="密码"
              :type="passwordShow ? 'password' : 'text' " :left-icon="passwordShow?'closed-eye':'eye-o'"
              placeholder="请输入密码"
              :rules="[{ required: true, message: '请填写密码' }]"
          />
        </div>

        <div style="margin: 16px;">
          <van-button class="login-btn" block type="info" native-type="submit">登陆</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>

import { login} from '@http/APIConfig.js'
import Cookies from "js-cookie";
export default {
  components: {},
  data() {
    return {
      showPopover: false,
      actions: [
      ],
      username: "",
      password: "",
      outerHeight: '',
      loginHeight: '',
      info: '',
      passwordShow: true,
    };
  },
  methods: {
    onSelect(e){
      this.username = e.username
      this.password= e.password
    },
    focused() {
      this.$nextTick(e => {
        document.querySelector("#aaa").scrollIntoView(true)
      })
      setTimeout(() =>{
        document.querySelector("#aaa").scrollIntoView(true)
      },100)
    },
    toLogin() {
      // Cookies.set('token','a')
      // this.$router.push( '/Main').catch(() =>{});
      //

      this.$toast.loading({
        duration: 0,
        message: '正在登录...',
      });
      let param = {
        password: this.password,
        username: this.username,
        type:'h5'

      }

      login(param).then(res => {
        if (res.code == 200) {
          this.info = res
          this.$toast.clear()
          Cookies.set('token', res.token)

          // window.localStorage.setItem('setUserInfo', JSON.stringify(res.result.userInfo))
          //
          // this.$store.dispatch("setUserName", '');
          //
          // this.saveUser()
          this.$router.push('/main').catch(() => {
          });
        }
      })
    },
    saveUser(){
      if(localStorage.getItem('userInfo')){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let usernameList = userInfo.map(item => {
          return item.username
        })
        let pass =  usernameList.includes(this.username)

        //更新账号密码操作
        if(pass){
          let index = usernameList.lastIndexOf(this.username)
          userInfo.splice(index, 1, {  password: this.password,username: this.username})
        }
        //新增账号密码操作
        if(!pass||userInfo.length == 0){
          userInfo.unshift({
            password: this.password,
            username: this.username
          })
          if(userInfo.length > 3){
            userInfo = userInfo.slice(0, 3)
          }
          localStorage.setItem('userInfo', JSON.stringify(userInfo))
        }

      }else{
        localStorage.setItem('userInfo', JSON.stringify([{  password: this.password,username: this.username}]))
      }
    },
    startLogin(){
      document.addEventListener('plusready', function () { // 这里一定要加上  不然会报push 未定义
        plus.android.requestPermissions(['android.permission.CAMERA'], function (e) {
          if (e.deniedAlways.length > 0) {    //权限被永久拒绝
            // 弹出提示框解释为何需要定位权限，引导用户打开设置页面开启
            //alert('永久');
            document.addEventListener("plusready", onPlusReady, false);

            // 扩展API加载完毕，现在可以正常调用扩展API
            function onPlusReady() {
              // 创建扫码控件来调获取手机的相机权限
              barcode = plus.barcode.create('barcode', [plus.barcode.QR], {
                top: '-9999', //改为-9999px隐藏该页面
                left: '0',
                width: '100%',
                height: '500px',
                position: 'static'
              });
              //plus.webview.currentWebview().append(barcode);;
              //barcode.start();开始扫码识别（我们把这句代码注释了，因为我们不需要扫描任何东西）
            }

            //上面打开权限后，这里直接重启APP
            // plus.runtime.restart();
          }
          if (e.deniedPresent.length > 0) {   //权限被临时拒绝
            // 弹出提示框解释为何需要定位权限，可再次调用plus.android.requestPermissions申请权限
            //alert('临时 ');
          }
          if (e.granted.length > 0) { //权限被允许
            //调用依赖获取定位权限的代码
            //alert('允许!!! ');

          }
        }, function (e) {
          alert('Request Permissions error:' + JSON.stringify(e));
        });
      }, false);
    }
  },
  mounted() {
    //获取屏幕高度
    this.outerHeight = window.outerHeight + 'px'
    this.loginHeight = this.$refs.login.clientHeight
    //配置一开始进入调用摄像头
    this.startLogin()

    if(localStorage.getItem('userInfo')){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.actions = userInfo.map(item =>{
       return {...item,...{text:item.username}}
      })
    }
  },
};
</script>

<style scoped lang="less">
.img-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
}

.logo {
  width: 200px;
  height: 80px;
  /* border-radius: 50px; */
  //margin-top: 80px;
}

.text {
  width: 100vw;
  text-align: center;
  margin: 0.3rem 0;
}

.input-bg {
  margin: 15px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  padding-bottom: 2px;
}

.base-submit-btn {
  font-size: 0.3rem;
}

.login {
  position: relative;


  height: 100vh;
  width: 100%;
  background-image:linear-gradient(#3eb7b6 10%,#3480ce);
  //background-image:linear-gradient(red 10%,#3eb7b6,green,#3480ce);
  //touch-action: none;
  //background-image: url("../../assets/login/login-bg.png");

  .login-form {
    box-sizing: border-box;
    width: 313px;
    background: #FFFFFF;
    box-shadow: 0px 5px 5px 1px rgba(2, 7, 20, 0.18);
    border-radius: 9px;
    margin: 50px auto 0;
    padding: 48px 21px;
    .van-popover__wrapper{
      display: block;
    }

    .login-text {
      font-weight: 500;
      font-size: 17px;
      color: #323F50;
    }

    .van-cell {
      padding: 0.267rem 0;
    }

    .login-btn {
      background: #006CDA;
      border-radius: 6px;
    }

    /deep/ .van-field__left-icon {
      position: absolute;
      left: 6px;
      top: 20px;
      z-index: 9;
    }

    /deep/ .password {
      position: relative;
    }

    /deep/ .van-field__control {
      padding-left: 30px !important;

    }


  }

  .login-bottom {
    position: absolute;
    text-align: center;
    bottom: 10px;
    font-size: 10px;
    font-weight: 400;
    color: #FFFFFF;
    width: 100%;
  }
}
</style>
