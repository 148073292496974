const user = {
    state: {
        userName: '',
        userInfo: {},
        carInfo: {},
        route: '',
    },
    actions: {
        setRoute({commit}, info) {
            commit('SETroute', info);
        },
        //
        setUserName({commit}, info) {
            commit('SETUserName', info);
        },
        setUserInfo({commit}, info) {
            commit('SETUserInfo', info);
        },
        setCarInfo({commit}, info) {
            commit('SETCarInfo', info);
        },

    }, mutations: {
        SETroute:(state, date)=>{
            state.route = date;
        },
        //定义用户信息
        SETUserName: (state, date) => {
            state.userName = date;
        },
        SETUserInfo: (state, date) => {
            state.userInfo = date;
        },
        SETCarInfo: (state, date) => {
            state.carInfo = date;
        },
    }

}
export default user
