
//肖
//const baseUrl = 'https://120.27.146.102:8081/api';
const baseUrl = 'https://m.hzmed.top/api';
import {get, post} from './HttpUtils'

/* 接口地址： */
//登陆
export const login = (params) => post(baseUrl + "/login", params)
//// 获取全部服务公司信息
export const getServiceCompanys = (params) => get(baseUrl + "/sourceCode/serviceCompany/getServiceCompanys", params)

export const deliverGood = (params) => post(baseUrl + "/sourceCode/code/deliverGood", params)

export const search = (params) => get(baseUrl + "/sourceCode/code/search", params)
export const eeaaa = (params) => get(baseUrl + "/sourceCode/code/search", params)





/*
使用方法 ：
1.在要使用的js文件导入
const API = require('../../../../http/APICongfig.js');
const API = require('@http/APIConfig.js');

2. 调用
API.getPageArrDic(params).then(res => {

}).catch(error => {

});

 */