<template>
  <router-view id="app">

  </router-view>
</template>

<script>

export default {
  name: "App",

  data() {
    return {
      number:0
    };
  },
  methods: {},
  created() {

    //
    // if(window.localStorage.getItem('setUserInfo')){
    //   let userInfo =JSON.parse(window.localStorage.getItem('setUserInfo'));
    //   this.$store.dispatch("setUserInfo", userInfo);
    // }
    //
    // //在页面加载时读取localStorage里的状态信息
    // localStorage.getItem("userMsg") && this.$store.replaceState(Object.assign(this.$store.state,JSON.parse(localStorage.getItem("userMsg"))));
    //
    // //在页面刷新时将vuex里的信息保存到localStorage里
    // window.addEventListener("beforeunload",()=>{
    //   localStorage.setItem("userMsg",JSON.stringify(this.$store.state))
    // })





  }
};
</script>

<style>
html{
  font-size: 16px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
</style>
